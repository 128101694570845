import { AuthenticationContext } from 'react-adal';
import * as configHelper from '../../utility/config/configHelper';

const adalConfig = {
    tenant: configHelper.azureAdTenantId,
    clientId: configHelper.azureAdClientId,
    endpoints: {
        api: `https://${configHelper.azureAdTenantId}/${configHelper.azureAdClientId}`,
    },
    // cacheLocation: "localStorage",
    postLogoutRedirectUri: window.location.origin,
    redirectUri: configHelper.thisLocation,
    cacheLocation: 'sessionStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
    return authContext.getCachedToken(authContext.config.clientId);
};
