import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'font-awesome/css/font-awesome.min.css';

import { runWithAdal } from 'react-adal';
import { authContext } from './authentication/azure-ad/adalConfig';

const DO_NOT_LOGIN = false;

runWithAdal(
    authContext,
    () => {
        // eslint-disable-next-line
        import('./indexApp.js');
    },
    DO_NOT_LOGIN
);
